import React from "react";
import styled from "styled-components";
import wl_tour from "../images/wl_tour_square.jpeg";
import { PurchaseLinks } from "../components/AlbumDetail/PurchaseLinks";

export const StyledHomeContainer = styled.div`
  font-family: ${props => props.theme.type.fontFamily};
  font-size: 20px;
  height: calc(100% - 108px);
  width: calc(100% - ${props => props.theme.layout.gutter * 2}px);
  color: ${props => props.theme.colors.text};
  background-color: ${props => props.theme.colors.background};

  padding: ${props => props.theme.layout.gutter}px;
  padding-bottom: ${props => props.theme.layout.gutter * 2}px;
  transition: all 0.25s ease-in-out;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 768px) {
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }
`;

const StyledHalfContainer = styled.div`
  width: 50%;

  img {
    height: auto;
    width: 90%;
    max-width: 550px;
  }

  a {
    width: 335px;
  }

  @media (max-width: 768px) {
    width: 100%;

    img {
      height: auto;
      width: 100%;
      max-width: 550px;
    }

    h2 {
      text-align: center;
    }
    a {
      width: 100%;
    }
  }
`;

const StyledTextWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Home = () => {
  return (
    <StyledHomeContainer>
      <StyledHalfContainer>
        <img src={wl_tour} alt="White Lighter Tour Poster" />
      </StyledHalfContainer>

      <StyledHalfContainer>
        <StyledTextWrap>
          <h2>White Lighter Anniversary</h2>
          <PurchaseLinks
            isGatsbyLink={true}
            text="Tickets"
            purchaseLink={"/tour"}
          />
        </StyledTextWrap>
      </StyledHalfContainer>
    </StyledHomeContainer>
  );
};

export default Home;
