import { Link } from "gatsby";
import React from "react";
import styled from "styled-components";

const StyledPurchaseLinks = styled.div`
  ${props => `
    a {
      display: block;
      padding: 12px 0;
      border: 2px solid ${props.theme.colors.text};
      text-decoration: none;
      text-align: center;
      color: ${props.theme.colors.text};
      background-color: transparent;
      width: 100%;
      margin-bottom: 20px;
      transition: all .15s ease-in-out;

      &:hover {
        color: ${props.theme.colors.background};
        background: ${props.theme.colors.text};
      }
    }
  
  `}
`;

export function PurchaseLinks({ purchaseLink, text, isGatsbyLink }) {
  return (
    <StyledPurchaseLinks>
      {isGatsbyLink ? (
        <Link to="/tour">{text}</Link>
      ) : (
        <a href={purchaseLink} target="_blank">
          {text ? text : "Purchase Album on Bandcamp"}
        </a>
      )}
    </StyledPurchaseLinks>
  );
}
